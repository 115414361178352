<template>
  <MainBanner></MainBanner>
  <MainServices></MainServices>
  <SaleOffers></SaleOffers>
  <PopularProducts></PopularProducts>
  <MainNews></MainNews>
  <MainBrands></MainBrands>
</template>

<script>
import MainBanner from "@/components/home/MainBanner";
import MainBrands from "@/components/home/MainBrands";
import MainServices from "@/components/home/MainServices";
import MainNews from "@/components/home/MainNews";
import PopularProducts from "@/components/home/PopularProducts";
import { mapActions } from "vuex";
import SaleOffers from "../components/home/SaleOffers.vue";

export default {
  components: {
    PopularProducts,
    MainNews,
    MainServices,
    MainBrands,
    MainBanner,
    SaleOffers,
  },
  created() {
    //this.fetchHomePage();
    // this.fetchContacts()
  },
  methods: {
    ...mapActions([
      "fetchHomePage",
      "fetchContacts",
      "checkAuth",
    ]),
  },
  async mounted() {
    await this.fetchHomePage();
    this.checkAuth();

    document.title = this.$store.state.page_meta.meta_title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', this.$store.state.page_meta.meta_description);
    } else {
      const newMeta = document.createElement('meta');
      newMeta.name = 'description';
      newMeta.content = this.$store.state.page_meta.meta_description;
      document.head.appendChild(newMeta);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>